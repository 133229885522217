<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>操作说明</span>
      </div>
    </div>
    <div class="inmg">
      <img src="@/assets/images/buz1.png" alt="" />
      <img src="@/assets/images/buz2.png" alt="" />
      <img src="@/assets/images/buz3.png" alt="" />
      <img src="@/assets/images/buz4.png" alt="" />
      <img src="@/assets/images/buz5.png" alt="" />
      <img src="@/assets/images/buz6.png" alt="" />
      <img src="@/assets/images/buz7.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.inmg {
  width: 100%;

  img {
    font-size: 0;
    width: 100%;
    height: 100%;
  }
}
</style>